<template>
  <div>
    <div v-if="mode === 'view'" class="plans-container">
      <CardPlan
        class="cards"
        :current-plan="plan"
        v-for="(plan, index) in getProfilePlans"
        :key="index"
        :btnName="plan.btnName"
        @selected="selectedPlan(plan)"
      />
    </div>
    <div v-else class="plans-container">
      <div class="cancel-title">
        Cancelar Premium
      </div>
      <template v-if="!displayCancelForm">
        <div class="cancel-speech">
          ¿Estás seguro de que quieres cancelar tu suscripción Premium?
        </div>
        <div class="btn-container">
          <div class="container-btn-available-plans">
            <button class="black btn-available-plans" @click="displayCancelForm = true">Sí, cancelar</button>
          </div>
          <div class="container-btn-available-plans">
            <button class="white btn-available-plans" @click="mode = 'view'">Regresar</button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="cancel-speech form" v-if="displayCancelForm">
          ¿Podrías ayudarnos comentándonos la razón por la cual decides cancelar tu cuenta Premium? Hacemos esto con el
          fin de mejorar y así poder ofrecer la calidad de servicio que nuestros usuarios merecen.
        </div>
        <div class="radio-buttons">
          <div class="field">
            <BRadio v-model="cancelReason" native-value="Precio">
              Precio
            </BRadio>
          </div>
          <div class="field">
            <BRadio v-model="cancelReason" native-value="Funcionalidad de herramienta">
              Funcionalidad de herramienta
            </BRadio>
          </div>
          <div class="field">
            <BRadio v-model="cancelReason" native-value="Otro">
              Otro
            </BRadio>
          </div>
        </div>
        <Area v-if="cancelReason === 'Otro'" v-model="comment" />
        <div class="btn-container">
          <button
            class="no-rounded black"
            :class="availableBtn ? '' : ' disabled'"
            :disabled="!availableBtn"
            @click="cancelPlan"
          >
            Cancelar Premium
          </button>

          <button class="no-rounded white" @click="displayCancelForm = false">Regresar</button>
        </div>
      </template>
    </div>
    <Confirm
      :showModalConfirm="showModalConfirm"
      :question="question"
      @onConfirm="onConfirm"
      @onCancel="closeModalConfirm"
      textConfirm="Aceptar"
    />
  </div>
</template>

<script>
import CardPlan from '@/views/Auth/CardPlan';
import Area from '@/components/Form/Area';
import Confirm from '@/components/Modals/Confirm';
import { mapGetters, mapActions } from 'vuex';
import { clickBtnPlanesDisponiblesCancelar, clickBtnPlanesDisponiblesPorNombre } from '@/utils/analytics';
import { BRadio } from 'buefy/dist/esm/radio';

export default {
  name: 'AvailablePlans',
  components: { CardPlan, Area, Confirm, BRadio },
  data: () => ({
    mode: 'view',
    displayCancelForm: false,
    cancelReason: '',
    comment: '',
    showModalConfirm: false,
    question: '',
    action: '',
    plan_id: null,
  }),
  methods: {
    ...mapActions('profileGeneral', ['changePlan']),
    selectedPlan(plan) {
      if (plan.id === 0) {
        clickBtnPlanesDisponiblesCancelar(this.getPremiumAccount, this.getIsGolstats);
        this.mode = 'cancelPlan';
        return;
      }
      clickBtnPlanesDisponiblesPorNombre(plan.title.toLowerCase(), this.getPremiumAccount, this.getIsGolstats);
      this.plan_id = plan.id;
      this.question = `¿Esta seguro que desea cambiar su plan a ${plan.title}?`;
      this.action = 'setPlan';
      this.showModalConfirm = true;
    },
    onConfirm() {
      this[`${this.action}`]();
    },
    closeModalConfirm() {
      this.showModalConfirm = false;
    },
    setPlan() {
      const body = { plan_id: this.plan_id };
      this.changePlan(body);
    },
    async cancelPlan() {
      const body = {
        plan_id: 0,
        reason: this.cancelReason === 'Otro' ? this.comment : this.cancelReason,
      };
      await this.changePlan(body);
      this.cancelReason = '';
      this.comment = '';
      this.mode = 'view';
    },
  },
  computed: {
    ...mapGetters('profileGeneral', ['getProfilePlans']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    availableBtn() {
      return !['', 'Otro'].includes(this.cancelReason) || (this.cancelReason === 'Otro' && this.comment !== '');
    },
  },
};
</script>

<style lang="scss" scoped>
.plans-container {
  width: 80%;
  margin: auto;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  max-height: 60vh;
  overflow-y: auto;
  .cards {
    margin-bottom: 30px;
  }
  .cancel-title {
    width: 100%;
    padding: 10px 0 20px;
    font-family: Circular-Std-Bold;
    font-size: 25px;
    line-height: 0.48;
    color: #494a4b;
  }
  .cancel-speech {
    font-family: Avenir-Regular;
    font-size: 14px;
    color: #494a4b;
    font-size: 18px;
    line-height: 1.39;

    &.form {
      text-align: start;
    }
  }
  .radio-buttons {
    padding-top: 20px;
    padding-bottom: 20px;
    align-self: flex-start;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    font-family: Avenir-Medium;
    text-align: left;
    width: 98%;
  }
  .btn-container {
    display: flex;
    justify-content: space-evenly;
    padding: 30px 0 30px 0 !important;
    width: 100%;

    button {
      border: none;
      outline: none;
      padding: 14px 43px 14px 44px;
      border-radius: 24px;
      cursor: pointer;
    }
    .black {
      background-color: #292929;
      color: #ffffff;
    }
    .white {
      background-color: #ffffff;
      color: #292929;
      border: solid 1px #292929;
    }
    .disabled {
      background-color: #e5e5e5;
      cursor: not-allowed;
    }
    .no-rounded {
      border-radius: 6.1px;
      font-family: Circular-Std-Medium;
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 768px) {
  .plans-container .btn-container {
    button {
      padding: 10px 35px 10px 35px;
      width: 45%;
    }
    .no-rounded {
      font-size: 18px !important;
    }
  }
  .cancel-speech {
    padding: 0 10px;
  }
  .container-btn-available-plans {
    width: 45% !important;
  }
  .btn-available-plans {
    width: 100% !important;
    font-family: AvenirNextLTPro-Demi, serif;
    font-size: 18px;
  }
}
@media screen and (max-width: 500px) {
  .plans-container {
    width: 90%;
  }
  .btn-available-plans {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 420px) {
  .btn-available-plans {
    font-size: 15px !important;
  }
  .plans-container {
    .btn-container {
      button {
        padding: 8px 25px 8px 25px;
      }
      .no-rounded {
        font-size: 16px !important;
      }
    }
  }
}
@media screen and (max-width: 366px) {
  .btn-available-plans {
    font-size: 14px !important;
  }
  .plans-container {
    .btn-container {
      button {
        padding: 8px 20px 8px 20px;
      }
      .no-rounded {
        font-size: 14px !important;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .btn-available-plans {
    font-size: 13px !important;
  }
}
.black:hover {
  background-color: #020202 !important;
  color: #d7d6d6 !important;
}
.white:hover {
  color: #000000 !important;
  background-color: #eae7e7 !important;
}
</style>
