<template>
  <div class="input-container">
    <div class="input-container-label">
      {{ label }}
    </div>
    <div :style="'height:' + height" class="input-container-form__input">
      <img v-if="icon" :src="icon" alt="" class="input-container-icon" />
      <textarea
        :type="type"
        :placeholder="placeholder"
        :value="value"
        class="input-container-customized__input"
        @input="$emit('input', $event.target.value)"
      />
    </div>
    <span v-for="(err, i) in setErrors" :key="i" class="input-container-error__message">{{ err }}</span>
  </div>
</template>

<script>
export default {
  name: 'InputField',
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '80px',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    setErrors: [],
  }),
  watch: {
    error(val) {
      this.setErrors = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  font: Avenir-Regular;
  &-icon {
    height: 25px;
    align-self: center;
    padding-right: 5px;
  }
  &-label {
    text-align: left;
    font-family: Circular-Std;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.08px;
    color: #494a4b;
  }
  &-form__input {
    border-radius: 3.5px;
    border: solid 1px #c7c7c7;
    background-color: #ffffff;
    display: flex;
    width: 100%;
    padding-left: 20px;
  }
  &-customized__input {
    background-color: transparent;
    color: #737373;
    border: none;
    width: 100%;
  }
  &-error__message {
    color: red;
    font-family: Avenir-Regular;
    padding: 2px;
    font-size: 12px;
  }

  textarea::placeholder {
    color: #cccccc;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  input {
    width: inherit;
    font-size: 14px;
    color: #3e3e3e;
    text-align: left;
    font-family: Avenir-Regular;
    &::placeholder {
      color: #828282;
    }
    &:focus {
      outline: none;
    }
  }
}
.input-container-customized__input {
  margin: 5px 5px 5px -12px;
}
</style>
