<template>
  <div class="plan-content">
    <div class="plan-content-title" :style="`background:${currentPlan.style}`">
      <div class="plan-content-title_bold">
        {{ currentPlan.type }}
      </div>
      <div class="plan-content-title_light">- {{ currentPlan.title }} -</div>
    </div>
    <div class="plan-content-body">
      <div class="plan-content-speech_plan">
        <div v-html="currentPlan.description"></div>
        <div class="plan-content-speech_plan-border_bottom"></div>
      </div>
      <div class="plan-content-amount">
        <div class="plan-content-amount_plan">
          <span class="price">$ {{ currentPlan.price }} </span><span class="currency">{{ currentPlan.currency }}</span>
        </div>
        <div class="plan-content-amount_description">
          {{ currentPlan.additionalInfo }}
        </div>
      </div>
      <div class="plan-button current-plan" v-if="currentPlan.isCurrentPlan">
        <img class="plan-check" src="/icons/icn_check_rounded.svg" alt="Plan Actual" />
        <span>Plan Actual</span>
      </div>
      <div class="plan-button" @click="onClickSelected" v-else>
        {{ btnName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardPlan',
  props: {
    currentPlan: {
      type: Object,
      required: true,
    },
    btnName: {
      type: String,
      default: 'Seleccionar',
    },
  },
  methods: {
    onClickSelected() {
      this.$emit('selected');
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-content {
  min-width: 300px;
  max-width: 360px;

  &-title {
    justify-content: center;
    display: flex;
    flex-flow: column;
    border-radius: 13px 13px 0px 0px;
    padding: 25px 0px 35px;
    border: solid 1px #fdb432;
    font-family: Circular-Std;
    color: #ffffff;
    text-align: center;
    background: linear-gradient(5deg, #5503c2 -29%, #6434ff 33%, #a888ff 94%);
    &_bold {
      font-size: 36px;
      font-weight: 900;
      letter-spacing: -1.3px;
    }
    &_light {
      font-family: Avenir-Pro-Medium;
      font-size: 25px;
      line-height: 10px;
      font-weight: 500;
      letter-spacing: -0.7px;
    }
  }
  &-body {
    border-radius: 0px 0px 13px 13px;
    border: solid 1px #d3d1d9;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  &-speech_plan {
    padding: 20px 30px;
    font-family: Avenir-Regular;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.2px;
    text-align: center;
    color: #858484;
    position: relative;
    &-border_bottom {
      position: absolute;
      height: 1px;
      width: 30%;
      bottom: 0px;
      left: 35%;
      background-color: #d3d1d9;
    }
  }
  &-amount {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 30px;
    &_plan {
      display: flex;
      flex-flow: row;
      font-family: Circular-Std;
      font-size: 25px;
      font-weight: bold;
      line-height: 0.64;
      text-align: center;
      .price {
        color: #626262;
      }
      .currency {
        color: #d6d6d6;
        padding-left: 5px;
      }
    }
    &_description {
      padding-top: 10px;
      font-family: Avenir-Regular;
      font-size: 12px;
      font-weight: 500;
      line-height: 0.92;
      text-align: center;
      color: #9a9a9a;
    }
  }
  .plan-button {
    border-radius: 24px;
    margin-bottom: 25px;
    padding: 15px 35px;
    border: solid 1px #c7d7b6;
    font-family: Avenir-Demi;
    font-size: 18px;
    line-height: 0.67;
    text-align: center;
    color: #626262;
    cursor: pointer;

    &:hover {
      background-color: #cde8b5;
      position: relative;
    }
  }
}
.current-plan {
  padding: 12px 35px !important;
}
.plan-check {
  margin: 0 5px 0 0;
}
</style>
